<div id="login_wrapper">
    <mat-card id="login-card">
        <div class="brand"><img src="./assets/img/logo_asso.png" /></div>
        <mat-card-title>Plateforme <strong>Autoconso</strong></mat-card-title>
        <mat-card-content>
            <div>
                <form [formGroup]="angForm" (ngSubmit)="postdata(angForm)" autocomplete="off">
                    <mat-form-field appearance="fill">
                        <mat-icon>person</mat-icon>
                        <mat-label>Email</mat-label>
                        <input type="email" matInput formControlName="email" placeholder="Ex. pat@example.com">
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-icon>lock icon</mat-icon>
                        <mat-label>Mot de passe</mat-label>
                        <input type="password" matInput formControlName="password" placeholder="Mot de passe">
                    </mat-form-field>
                    <mat-card-actions>
                        <button mat-stroked-button type="submit">Se connecter</button>
                    </mat-card-actions>
                </form>
            </div>
            <!-- BUG ajouter get sur l'email -->
            <a href="{{baseUrl}}/src/password/enter_email.php" id="lostPass">Mot de passe oublié</a>
        </mat-card-content>
    </mat-card>
</div>
